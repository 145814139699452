<template>
  <div class="hello d-flex message-container justify-center">
    <div class="align-self-center">
      <div style="text-align: center" class="text-h3">
        You do not have access to this page
      </div>
      <div
        v-if="url"
        style="text-align: center"
        class="text-subtitle-1 text-decoration-underline"
      >
        {{ url }}
      </div>
      <div style="text-align: center" class="text-subtitle-1">
        Contact your system super administrator
      </div>
      <div style="text-align: center">
        <v-btn color="primary" rounded :to="{ name: 'HomePage' }" class="mt-12">
          Home page
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotAllowed",
  computed: {
    url() {
      let url = "";
      if (this.$route.query && this.$route.query.url) {
        url = decodeURIComponent(this.$route.query.url);
      }
      return url;
    },
  },
};
</script>

<style>
.message-container {
  height: 600px;
  padding-left: 100px;
  max-height: 100vh;
}
</style>
